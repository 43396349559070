
import { defineComponent } from "vue";
import { toFormValidator } from "@vee-validate/zod";
import { z } from "zod";
import { useField, useForm } from "vee-validate";
import { auth } from "@/firebase";
import { useI18n } from "vue-i18n";
import firebase from "firebase";

interface RegisterFormValues {
  name?: string;
  email?: string;
  password?: string;
}

export interface RegisterSuccessObject {
  uid?: string;
  name: string;
}

/**
 * This component shows a login card component.
 */
export default defineComponent({
  name: "RegisterForm",
  emits: ["registerError", "roleError", "registerSuccess"],
  setup(props, context) {
    // Grab emit from context and get a router object.
    const { emit } = context;
    const { t } = useI18n();

    // Zod schema validation.
    const validationSchema = toFormValidator(
      z.object({
        name: z.string().nonempty(t("register.error.empty")),
        email: z
          .string()
          .nonempty(t("login.email.error.empty"))
          .email({ message: t("login.email.error.wrong") }),
        password: z
          .string()
          .nonempty(t("login.password.error.empty"))
          .min(6, { message: t("login.password.error.wrong") }),
      })
    );

    const { handleSubmit, errors, resetForm, isSubmitting } = useForm({
      validationSchema,
    });

    const { value: email } = useField("email");
    const { value: password } = useField("password");
    const { value: name } = useField("name");

    const onSubmit = handleSubmit(async (values: RegisterFormValues) => {
      const { name, email, password } = values;

      // If the values are somehow undefined we return.
      if (!email || !password || !name) return;

      try {
        // Login using email/password
        const userCredentials = await auth.createUserWithEmailAndPassword(
          email,
          password
        );

        const returnObject: RegisterSuccessObject = {
          uid: userCredentials.user?.uid,
          name: name,
        };

        resetForm();

        emit("registerSuccess", returnObject);
      } catch (e) {
        console.error(e);
        if (e.code === "auth/weak-password")
          emit("registerError", t("register.error.weak_password"));
        else if (e.code === "auth/email-already-in-use")
          emit("registerError", t("register.error.user_exists"));
        else emit("registerError", e.message);
      }
    });

    return {
      validationSchema,
      email,
      password,
      name,
      errors,
      onSubmit,
      isSubmitting,
    };
  },
  methods: {
    backToLogin: async function () {
      await this.$router.push({
        name: "Login",
        params: { locale: this.$i18n.locale },
      });
    },
  },
});
