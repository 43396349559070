
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import { auth, registerUser } from "@/firebase";
import { User } from "@app/shared";
import { MutationTypes } from "@/store/mutations/types";
import { mapMutations } from "vuex";
import RegisterForm, {
  RegisterSuccessObject,
} from "@/components/RegisterForm.vue";

export default defineComponent({
  name: "Register",
  components: {
    RegisterForm,
  },
  created() {
    if (this.$route.query.needsLogin) {
      ElMessage({
        type: "warning",
        message: this.$t("login.login_to_access"),
      });
    }
  },
  methods: {
    ...mapMutations({
      setAdminState: MutationTypes.SET_IS_ADMIN,
    }),
    async registerUser(successObject: RegisterSuccessObject) {
      // We get the custom claims from the idToken
      const registerResult = await registerUser(successObject);

      // Some unknown error occurred.
      if (!registerResult.data.success) {
        this.showErrorNotification(this.$t("register.backend.error"));
        await auth.signOut();
        return;
      }

      if (auth.currentUser) await auth.currentUser.reload();

      const idTokenResult = await auth.currentUser?.getIdTokenResult(true);

      // Setup the roles allowed to login.
      const allowedRoles: User.RoleArray = ["admin", "teacher"];

      // If the logged in user is in the allowed role we log in
      if (
        idTokenResult?.claims.role &&
        allowedRoles.includes(idTokenResult.claims.role)
      ) {
        this.setAdminState(idTokenResult.claims.role === "admin");
        await this.$router.push({
          path:
            (this.$route.query.redirect as string) ??
            `/${this.$i18n.locale}/dashboard/levels`,
        });
      } else {
        await auth.signOut();
        this.showErrorNotification(this.$t("login.error.not_allowed"));
      }
    },
    showErrorNotification(message: string) {
      ElMessage({
        type: "error",
        message,
      });
    },
  },
});
